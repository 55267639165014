import "../css/Question.css";
import TextWithScroll from "./TextWithScroll";
import RadioWithLabel from "./RadioWithLabel";
import ButtonComponent from "./ButtonComponent";
import { useState } from "react";
import { getAudioStatus, getText, playHowlAudio } from "../helper/Helper";

const Question = (props) => {
  const [selectedId, setSelectedId] = useState(null);

  function onOptionSelect(index) {
    setSelectedId(index);
  }

  function submitHandler() {
    props.onSubmitClick(
      selectedId === props.dataObject.answer ? "correct" : "incorrect",
      props.tileClicked,
      props.isBonusTile,
      props.dataObject.options[selectedId - 1]
    );
    if (getAudioStatus())
      playHowlAudio({ id: selectedId === props.dataObject.answer ? "correct" : "incorrect" });
  }
  return (
    <div className="Question">
      <div className="questionWrapper">
        <div className="questionHolder">
          <TextWithScroll text={props.dataObject.question} width="550px" height="250px" />
        </div>
        <div className="optionHolder">
          {props.dataObject.options.map((option, i) => {
            return (
              <RadioWithLabel
                optionText={option}
                key={i + 1}
                index={i + 1}
                optionsData={props.dataObject.options}
                onRadioClick={onOptionSelect}
                isSelected={selectedId === i + 1}
              />
            );
          })}
        </div>
        <ButtonComponent
          text={getText("submit")}
          height="85px"
          width="311px"
          type="text"
          styleType="submit"
          top="92%"
          left="auto"
          clickHandler={submitHandler}
          disabled={!selectedId}
        />
      </div>
    </div>
  );
};

export default Question;
