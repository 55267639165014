import React from "react";
import { getImages, getText } from "../helper/Helper";
import "../css/SubmitScore.css";
import { useState } from "react";
import ButtonComponent from "./ButtonComponent";

const SubmitScore = (props) => {
  const [closeBtnSrc, setCloseBtnSrc] = useState(getImages("close_btn_normal"));
  const [name, setName] = useState("");

  function handleCloseBtnClick() {
    props.hideSubmitScorePopup();
  }
  function handleSubmitBtnClick() {
    const nameString = name.charAt(0).toUpperCase() + name.slice(1);
    props.storeSubmitScore(nameString);
  }

  return (
    <div
      className="submitScore"
      style={{ backgroundImage: `url(${getImages("submit_score_bg")})` }}
    >
      <div className="nameLabel label">{getText("name")}</div>
      <input
        type="text"
        className="inputBox"
        value={name}
        onChange={(e) => {
          const result = e.target.value.replace(/[^a-z]/gi, "");
          setName(result);
        }}
        maxLength="10"
      ></input>
      <button
        className="closeBtn"
        style={{ backgroundImage: `url(${closeBtnSrc})` }}
        onClick={handleCloseBtnClick}
        onMouseEnter={() => {
          setCloseBtnSrc(getImages("close_btn_hover"));
        }}
        onMouseLeave={() => {
          setCloseBtnSrc(getImages("close_btn_normal"));
        }}
      ></button>
      <div className="infoHolder">
        <img className="infoIcon" src={getImages("info_icon")}></img>
        <div className="infoText">{getText("inputInfoData", true)}</div>
      </div>
      <ButtonComponent
        text={getText("submit")}
        height="85px"
        width="311px"
        type="text"
        styleType="submit"
        top="60%"
        left="29%"
        clickHandler={handleSubmitBtnClick}
        disabled={name === ""}
      ></ButtonComponent>
    </div>
  );
};

export default SubmitScore;
