import { getImages, getText } from "../helper/Helper";
import "../css/Menu.css";
import ButtonComponent from "./ButtonComponent";

const Menu = (props) => {
  function instructionButtonHandler() {
    props.onInstructionClick();
  }
  function leaderBoardButtonHandler() {
    props.onLeaderboardClick();
  }
  function furtherInfoButtonHandler() {
    props.onFurtherInfoClick();
  }
  function startQuizHandler() {
    props.onStartQuizClick();
  }
  return (
    <div className="Menu" style={{ backgroundImage: `url(${getImages("info_bg")})` }}>
      <img src={getImages("main_heading")} className="mainHeading"></img>
      <div className="blueBox">
        <ButtonComponent
          text={getText("instruction")}
          height="86px"
          width="432px"
          type="text"
          styleType="normal"
          top="38%"
          left="auto"
          clickHandler={instructionButtonHandler}
        ></ButtonComponent>
        <ButtonComponent
          text={getText("leaderboard")}
          height="86px"
          width="432px"
          type="text"
          styleType="normal"
          top="49%"
          left="auto"
          clickHandler={leaderBoardButtonHandler}
        ></ButtonComponent>
        <ButtonComponent
          text={getText("furtherInfo")}
          height="86px"
          width="432px"
          type="text"
          styleType="normal"
          top="60%"
          left="auto"
          clickHandler={furtherInfoButtonHandler}
        ></ButtonComponent>
      </div>
      <ButtonComponent
        text={getText("startQuiz")}
        height="85px"
        width="311px"
        type="text"
        styleType="submit"
        top="88%"
        left="auto"
        clickHandler={startQuizHandler}
      ></ButtonComponent>
    </div>
  );
};

export default Menu;
