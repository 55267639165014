import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from "react";
import "../css/Timer.css";

const Timer = forwardRef((props, ref) => {
  const [duration, setDuration] = useState(props.timeout);
  const [isStarted, setStart] = useState(false);
  const startTime = useRef(null);
  const elapsedTime = useRef(0);
  const timerInterval = useRef(null);
  const timeOut = useRef(props.timeout * 1000);

  useImperativeHandle(ref, () => ({
    startTimer() {
      if (!timerInterval.current) {
        setTimeout(() => {
          startTime.current = Date.now() - elapsedTime.current;
          timerInterval.current = setInterval(updateTimer, 10);
          setStart(true);
        }, 500);
      }
    },

    pauseTimer() {
      if (timerInterval.current) {
        clearInterval(timerInterval.current);
        timerInterval.current = null;
      }
    },

    resumeTimer() {
      if (!timerInterval.current && isStarted) {
        startTime.current = Date.now() - elapsedTime.current;
        timerInterval.current = setInterval(updateTimer, 10);
      }
    },

    getTimeLeft() {
      return duration;
    },

    clearTimer() {
      clearInterval(timerInterval.current);
      timerInterval.current = null;
      elapsedTime.current = null;
    },
  }));

  const updateTimer = () => {
    if (elapsedTime.current !== null) {
      const currentTime = Date.now();
      elapsedTime.current = currentTime - startTime.current;
      let time = timeOut.current - elapsedTime.current;

      if (time > 0) {
        const seconds = Math.floor(time / 1000);
        setDuration(seconds);
      } else {
        clearInterval(timerInterval.current);
        timerInterval.current = null;
        setDuration(0);
        props.onTimeout();
      }
    }
  };

  const timeZeros = () => {
    let timeZeros = "";
    // if (duration >= 100) {
    //   timeZeros = "";
    // } else if (duration < 100 && duration >= 10) {
    //   timeZeros = props.timeout >= 100 ? "0" : "";
    // } else {
    //   timeZeros = "0";
    // }
    if (props.timeout >= 100) {
      if (duration >= 10) timeZeros = "0";
      else timeZeros = "00";
    } else if (props.timeout >= 10 && duration < 10) {
      timeZeros = "0";
    }
    return timeZeros;
  };

  return <div className="Timer">{`${timeZeros()}${duration}`}</div>;
});

export default Timer;
