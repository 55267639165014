/** @format */

/* Setting the initial state of the store. */
const initialState = {
  questionIndex: null,
  attemptCount: 0,
  userData: {
    name: null,
  },
  resultData: {},
};

/**
 * If the action type is UPDATE_DATA, then return a new state object with the courseData
 * property set to the payload of the action.
 * @param [state] - This is the current state of the store.
 * @param action - The action object that was dispatched.
 * @returns The state is being returned.
 */

const condition = (state = initialState, action) => {
  switch (action.type) {
    case "QUESTION_INDEX":
      return {
        ...state,
        questionIndex: action.payload,
      };
    case "ATTEMPT_COUNT":
      return {
        ...state,
        attemptCount: action.payload,
      };
    case "USER_DATA":
      return {
        ...state,
        userData: action.payload,
      };
    case "RESULT_DATA":
      return {
        ...state,
        resultData: action.payload,
      };
    default:
      return state;
  }
};

export default condition;
