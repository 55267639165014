import React, { useEffect, useState, useRef } from "react";
import "../css/ButtonComponent.css";
import { getImages, mouseEvents } from "../helper/Helper";
const ButtonComponent = (props) => {
  // props list -> height width top left type text styleType clickHandler normalImage hoverImage
  // type -> text or image
  //styleType -> normal or submit
  let {
    height,
    width,
    top,
    left,
    type = "text",
    text = "",
    styleType = "normal",
    clickHandler = () => {},
    disabled = false,
  } = props;

  const ref = useRef(null);

  const [hoverState, setHoverState] = useState(false);
  const [buttonHovered, setButtonHovered] = useState(false);

  let customStyle = {
    width: width,
    height: height,
    top: top,
    left: left,
  };

  return type === "text" ? (
    <div
      className={`buttonComponent ${styleType} ${buttonHovered && "hovered"}  ${
        disabled && "disabled"
      }`}
      onClick={clickHandler}
      onMouseOver={() => {
        setButtonHovered(true);
      }}
      onMouseOut={() => {
        setButtonHovered(false);
      }}
      style={customStyle}
    >
      {props.icon && <img className="buttonIcon" src={getImages(props.icon)}></img>}
      <div className="buttonText">{text}</div>
    </div>
  ) : (
    <img
      className={`imageButton ${disabled && "disabled"}`}
      style={customStyle}
      src={!hoverState ? props.normalImage : props.hoverImage}
      ref={ref}
      onMouseDown={mouseEvents.down === "mousedown" ? clickHandler : () => {}}
      onTouchStart={mouseEvents.down === "touchstart" ? clickHandler : () => {}}
      onMouseOver={() => {
        setHoverState(true);
      }}
      onMouseOut={() => {
        setHoverState(false);
      }}
    ></img>
  );
};

export default ButtonComponent;
