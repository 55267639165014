import "../css/ContentArea.css";
import Game from "./Game";
import {
  getAudioStatus,
  getGameConfig,
  onResizeFn,
  parseJsonData,
  playHowlAudio,
  stopHowlAudio,
} from "../helper/Helper";
import { useEffect, useState } from "react";
import Menu from "./Menu";
import Instruction from "./Instruction";
import FurtherInfo from "./FurtherInfo";
import Leaderboard from "./Leaderboard";
import Intro from "./Intro";
import Result from "./Result";
import FinishPage from "./FinishPage";
import { connect } from "react-redux";
import ReactGA from "react-ga4";

const ContentArea = (props) => {
  const gameConfig = getGameConfig();

  const [introScreen, setIntroScreen] = useState(true); //
  const [menuScreen, setMenuScreen] = useState(false);
  const [instructionScreen, setInstructionScreen] = useState(false);
  const [leaderboardScren, setLeaderboardScreen] = useState(false);
  const [furtherInfoScreen, setFurtherInfoScreen] = useState(false);
  const [gameScreen, setGameScreen] = useState(false); //
  const [resultScreen, setResultScreen] = useState(false);
  const [finishScreen, setFinishScreen] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);

  const localDebugMode = window.location.href.includes(gameConfig.hostedURL) ? false : true;

  useEffect(() => {
    onResizeFn();
    window.onresize = onResizeFn;
  }, []);

  const onIntroEnterClick = () => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "Game Accessed" });
    setIntroScreen(false);
    setMenuScreen(true);
  };



  const onStartQuizClick = () => {
    setResultScreen(false);
    setMenuScreen(false);
    setGameScreen(true);
    playBGMusic();
  };

  const playBGMusic = () => {
    if (getAudioStatus()) {
      playHowlAudio({
        id: "bg_music",
        onEnded: () => {
          playBGMusic();
        },
      });
    }
  };

  const onGameEnd = () => {
    setGameScreen(false);
    stopHowlAudio({ id: "bg_music" });
    // pull data
    if (!localDebugMode) {
      pullAPI((data) => {
        let score = data.length === 0 ? 0 : data[0].score;
        setResultScreen({
          highestScore:
            parseInt(score) < props.resultData.score ? props.resultData.score : parseInt(score),
        });
      });
    } else {
      setResultScreen({
        highestScore: 5000,
      });
    }
  };

  const onSubmitScore = (username) => {
    setResultScreen({
      ...resultScreen,
      isScoreSubmitted: true,
    });

    props.updateUserdata({
      ...props.userData,
      username,
    });

    // push data

    let dbObj = {
      username: username,
      score: props.resultData.score,
      response: JSON.stringify(props.resultData.response).replace(/'/g, "\\'"),
    };

    if (!localDebugMode) {
      pushAPI(dbObj, () => {});
    }
  };

  const pushAPI = (dataObj, cb) => {
    setFetchingData(true);
    let http = new XMLHttpRequest();
    let url = window.location.href.includes(gameConfig.finalHostedURL)
      ? gameConfig.phpData.post
      : gameConfig.phpData.debugPost;
    http.open("POST", url, true);

    //Send the proper header information along with the request
    http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

    http.onreadystatechange = function () {
      //Call a function when the state changes.
      if (http.readyState == 4 && http.status == 200) {
        setFetchingData(false);
        cb();
      }
    };

    // Turn the data object into an array of URL-encoded key/value pairs.
    let urlEncodedData = "",
      urlEncodedDataPairs = [],
      name;
    for (name in dataObj) {
      urlEncodedDataPairs.push(encodeURIComponent(name) + "=" + encodeURIComponent(dataObj[name]));
      urlEncodedData += encodeURIComponent(name) + "=" + encodeURIComponent(dataObj[name]) + "&";
    }
    urlEncodedData = urlEncodedData.substring(0, urlEncodedData.length - 1);
    http.send(urlEncodedData);
  };

  const onHomeClick = () => {
    stopHowlAudio({ id: "bg_music" });
    setGameScreen(false);
    setFinishScreen(false);
    setMenuScreen(true);
  };

  const onFinishPageClick = () => {
    setResultScreen(false);
    setFinishScreen(true);
  };

  const onLeaderboardClick = () => {
    //pull data
    if (!localDebugMode) {
      pullAPI((data) => {
       setLeaderboardScreen(data);
      });
    } else {
      setLeaderboardScreen(leaderBoardDataLocal);
    }
  };

  const pullAPI = (cb) => {
    setFetchingData(true);
    let http = new XMLHttpRequest();
    let url = window.location.href.includes(gameConfig.finalHostedURL)
      ? gameConfig.phpData.get
      : gameConfig.phpData.debugGet;
    http.open("GET", url, true);
    //Send the proper header information along with the request
    http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    http.onreadystatechange = function () {
      //Call a function when the state changes.
      if (http.readyState == 4 && http.status == 200) {
        setFetchingData(false);
        cb(parseJsonData(http.responseText));
      }
    };
    http.send();
  };

  return (
    <div className="ContentArea">
      <div className="contentWrapper">
        {fetchingData && (
          <div className="fetchingData">
            <div className="loader"></div>
          </div>
        )}
        {introScreen && <Intro onEnterClick={onIntroEnterClick} />}
        {menuScreen && !instructionScreen && !leaderboardScren && !furtherInfoScreen && (
          <Menu
            onInstructionClick={() => setInstructionScreen(true)}
            onLeaderboardClick={onLeaderboardClick}
            onFurtherInfoClick={() => setFurtherInfoScreen(true)}
            onStartQuizClick={() => onStartQuizClick(true)}
          />
        )}
        {instructionScreen && <Instruction onBackClick={() => setInstructionScreen(false)} />}
        {leaderboardScren && (
          <Leaderboard
            leaderboardData={leaderboardScren}
            onBackClick={() => setLeaderboardScreen(false)}
          />
        )}
        {furtherInfoScreen && <FurtherInfo onBackClick={() => setFurtherInfoScreen(false)} />}
        {gameScreen && <Game onGameEnd={onGameEnd} onHomeClick={onHomeClick} />}
        {resultScreen && !gameScreen && !leaderboardScren && (
          <Result
            onSubmitScore={onSubmitScore}
            onPlayAgainClick={onStartQuizClick}
            onFinishPageClick={onFinishPageClick}
            onLeaderboardClick={onLeaderboardClick}
            highestScore={resultScreen.highestScore}
            isScoreSubmitted={resultScreen.isScoreSubmitted}
          />
        )}
        {finishScreen && <FinishPage onHomeClick={onHomeClick} />}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    resultData: state.resultData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserdata: (data) =>
      dispatch({
        type: "USER_DATA",
        payload: data,
      }),
  };
};

const leaderBoardDataLocal = [
  {
    username: "abc",
    score: 4100,
  },
  {
    username: "def",
    score: 6900,
  },
  {
    username: "ghi",
    score: 3800,
  },
  {
    username: "jkl",
    score: 7700,
  },
  {
    username: "mno",
    score: 600,
  },
  {
    username: "pqr",
    score: 1300,
  },
  {
    username: "stu",
    score: 4200,
  },
  {
    username: "vwx",
    score: 2900,
  },
  {
    username: "yzz",
    score: 4500,
  },
  {
    username: "aa",
    score: 300,
  },
];

export default connect(mapStateToProps, mapDispatchToProps)(ContentArea);
