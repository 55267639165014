import React from "react";
import { getImages, getText } from "../helper/Helper";
import "../css/Feedback.css";
import ButtonComponent from "./ButtonComponent";
import TextWithScroll from "./TextWithScroll";
import ReactHtmlParser from "html-react-parser";

const Feedback = (props) => {
  let imageSrc = getImages(`${props.answer ? "correct" : "incorrect"}`);
  let iconText = props.answer ? getText("correctText") : getText("wrongText");

  return (
    <div className="feedbackMainContainer">
      <div className="iconWrapper">
        <img src={imageSrc}></img>
        <div className="iconText">{iconText}</div>
      </div>
      <div className="feedbackContainer">
        <TextWithScroll
          text={ReactHtmlParser(props.feedback)}
          height="510px"
          width="500px"
        ></TextWithScroll>
      </div>
      <ButtonComponent
        text={getText("next")}
        height="85px"
        width="311px"
        type="text"
        styleType="submit"
        top="92%"
        left="auto"
        clickHandler={() => props.onNextClick(props.answer, props.tileClicked)}
      />
    </div>
  );
};

export default Feedback;
