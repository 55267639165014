import HexgonTile from "./HexagonTile";
import "../css/Game.css";
import {
  getText,
  getGameConfig,
  getImages,
  getQuestionBank,
  shuffleArray,
  setShiftedPhase,
  getShiftedPhase,
  setScreenPause,
  getScreenPause,
  pauseHowlAudio,
  resumeHowlAudio,
} from "../helper/Helper";
import { useState, useRef, useEffect } from "react";
import Question from "./Question";
import { connect } from "react-redux";
import Feedback from "./Feedback";
import Timer from "./Timer";
import ButtonComponent from "./ButtonComponent";
import PauseMenu from "./PauseMenu";

const Game = (props) => {
  const gameConfig = getGameConfig();

  const vCount = gameConfig.hexgonData.vCount;
  const hCount = gameConfig.hexgonData.hCount;

  const ref = useRef(null);
  const questionBank = useRef(null);
  const timerRef = useRef(null);
  const mapRef = useRef(null);
  const hexagonTileRef = useRef([...Array(vCount * hCount)]);
  const finishLineRef = useRef(null);

  const specialTilesSet = useRef(shuffleArray(gameConfig.specialTilesSet)[0]);

  const [isGameLoaded, setGameLoad] = useState(false);
  const [displayQuestion, setDisplayQuestion] = useState(false);
  const [displayFeedback, setDisplayFeedback] = useState(false);
  const [displayPauseMenu, setDisplayPauseMenu] = useState(false);
  const [displayDevicePlayButton, setDisplayDevicelayButton] = useState(false);
  const [activeTiles, setActiveTiles] = useState([]);
  const [currentTile, setCurrentTile] = useState(null);
  const [tilePath, setTilePath] = useState([]);
  const [timerStatus, setTimerStatus] = useState(true);
  const [gameResetKey, setGameResetKey] = useState(false);

  let leftDistance = gameConfig.hexgonData.width - gameConfig.hexgonData.width / 4.53;
  let bottomDistance = gameConfig.hexgonData.height + gameConfig.hexgonData.height / 42.33;

  useEffect(() => {
    if (!isGameLoaded) {
      questionBank.current =
        props.attemptCount === 0 ? getQuestionBank() : shuffleArray(getQuestionBank());
      props.updateQuestionIndex(0);
      props.updateAttemptCount(props.attemptCount + 1);
      setGameLoad(true);
      finishLineRef.current.style.bottom = `${bottomDistance * vCount}px`;
      finishLineRef.current.style.left = `${leftDistance / 1.196}px`;

      finishLineRef.current.style.opacity = "1";

      // timerRef.current.startTimer();

      window.onblur = function (ev) {
        if (timerRef.current && !getScreenPause()) {
          timerRef.current.pauseTimer();
        }
        pauseHowlAudio({ id: "bg_music" });
      };

      window.onfocus = function (ev) {
        onScreenFocusIn();
      };

      window.reducetime = (time) => timerRef.current.reduceTime(time);
    }
    // eslint-disable-next-line
    return () =>{

      window.onblur = () => {}

      window.onfocus = () => {}

    }
  }, []);

  const onScreenFocusIn = () => {
    if (timerRef.current && !getScreenPause()) {
      timerRef.current.resumeTimer();
    }
    resumeHowlAudio({ id: "bg_music" });
  };

  const onTileClick = (index, isBonusTile) => {
    setDisplayQuestion({ tileClicked: index, isBonusTile });
    setTimerStatus(false);
  };

  const onSubmitClick = (status, tileClicked, isBonusTile, optionSelected) => {
    // status = "correct";
    setDisplayQuestion(false);
    setDisplayFeedback({ status, tileClicked });
    if (status === "correct") {
      let score = props.resultData.score;
      score += gameConfig.scoringData.correctAttempt;
      if (isBonusTile) score += gameConfig.scoringData.bonusAttempt;
      props.updateResultData({
        ...props.resultData,
        score,
        questionsAnswered: props.resultData.questionsAnswered + 1,
        bonusCollected: isBonusTile
          ? props.resultData.bonusCollected + 1
          : props.resultData.bonusCollected,
        response: [
          ...props.resultData.response,
          {
            question: questionBank.current[props.questionIndex].question,
            answer: optionSelected,
            feedback: "correct",
          },
        ],
      });
    } else {
      props.updateResultData({
        ...props.resultData,
        response: [
          ...props.resultData.response,
          {
            question: questionBank.current[props.questionIndex].question,
            answer: optionSelected,
            feedback: "incorrect",
          },
        ],
      });
    }
  };

  const onQuestionAttempt = (answer, tileClicked) => {
    props.updateQuestionIndex(
      props.questionIndex < questionBank.current.length - 1 ? props.questionIndex + 1 : 0
    );
    setDisplayFeedback(false);

    if (answer) {
      if (specialTilesSet.current.bonusTiles.includes(tileClicked)) {
        hexagonTileRef.current[tileClicked].playBonusAnim(() => {
          if (tileClicked) {
            let pathArray = tilePath;
            pathArray.push(tileClicked);
            setTilePath(pathArray);
          }
          setCurrentTile(tileClicked);
          setTimerStatus(true);
        });
      } else {
        if (tileClicked) {
          let pathArray = tilePath;
          pathArray.push(tileClicked);
          setTilePath(pathArray);
        }
        setCurrentTile(tileClicked);
        setTimerStatus(true);
      }
    } else {
      setTimerStatus(true);
    }
  };

  const onPauseClick = () => {
    if (!displayQuestion && !displayFeedback) setTimerStatus(!timerStatus);
    setDisplayPauseMenu(!displayPauseMenu);
  };

  const onDevicePlayButtonClick = () => {
    onScreenFocusIn();
    setDisplayDevicelayButton(false);
  };

  useEffect(() => {
    let activeTilesArray = [];

    if (currentTile === null) {
      activeTilesArray = Array.from({ length: hCount }, (_, i) => i + 1).filter((n) => n % 2);
    } else {
      // if the tile is at first position
      if (currentTile % hCount === 1) {
        activeTilesArray = [currentTile + hCount, currentTile + 1];
      }
      // if the tile is at last position
      else if (currentTile % hCount === 0) {
        activeTilesArray = [currentTile - 1, currentTile + hCount];
      }
      // if the tile is at in between
      else {
        // if the tile from even column
        if ((currentTile % hCount) % 2 === 0) {
          activeTilesArray = [
            currentTile + hCount - 1,
            currentTile + hCount,
            currentTile + hCount + 1,
          ];
        }
        // if the tile from odd column
        else {
          activeTilesArray = [currentTile - 1, currentTile + hCount, currentTile + 1];
        }
      }
    }

    activeTilesArray = activeTilesArray.filter(
      (tile) => !specialTilesSet.current.blockedTiles.includes(tile)
    );

    if (currentTile > vCount * hCount - hCount && currentTile <= vCount * hCount) {
      // if (currentTile > 5 && currentTile <= 10) {
      timerRef.current.pauseTimer();
      let score = props.resultData.score;
      let timeLeft = timerRef.current.getTimeLeft();
      score += gameConfig.scoringData.goalReached;
      score += timeLeft * gameConfig.scoringData.timeBonus;
      props.updateResultData({
        ...props.resultData,
        score,
        goalReached: true,
        timeLeft,
      });
      timerRef.current.clearTimer();
      props.onGameEnd();
    } else {
      let newShiftedPhase = getShiftedPhase();
      if (currentTile > hCount * 3 * getShiftedPhase() && hCount * 3 * getShiftedPhase() + hCount) {
        newShiftedPhase++;
      }

      if (getShiftedPhase() !== newShiftedPhase) {
        let bottomInc = parseInt(mapRef.current.style.bottom) - gameConfig.hexgonData.height * 3;
        // if ((currentTile % hCount) % 2 === 0) bottomInc -= gameConfig.hexgonData.height / 2;
        mapRef.current.style.bottom = `${bottomInc}px`;
        setShiftedPhase(newShiftedPhase);
      }

      // if (getShiftedPhase() === 0) {
      //   mapRef.current.style.bottom = `${-12}px`;
      //   setShiftedPhase(1);
      // }
      setActiveTiles(activeTilesArray);
    }
    // eslint-disable-next-line
  }, [currentTile]);

  useEffect(() => {
    if (timerStatus) {
      setScreenPause(false);
      timerRef.current.resumeTimer();
    } else {
      setScreenPause(true);
      timerRef.current.pauseTimer();
    }
  }, [timerStatus]);

  useEffect(() => {
    setTimerStatus(true);
    setCurrentTile(null);
    setDisplayPauseMenu(false);
    setDisplayQuestion(false);
    setDisplayFeedback(false);
    setTilePath([]);
    setShiftedPhase(1);
    timerRef.current.startTimer();
    props.updateResultData({
      ...props.resultData,
      score: 0,
      questionsAnswered: 0,
      goalReached: false,
      bonusCollected: 0,
      timeLeft: 0,
      response: [],
    });
    // eslint-disable-next-line
  }, [gameResetKey]);

  return (
    <div className="Game" ref={ref} key={gameResetKey}>
      <div className="scoreWrapper flexed">
        <div className="timer flexed">
          <img alt="timerBg" className="timerBg" src={getImages("timer_panel")}></img>
          <Timer ref={timerRef} timeout={gameConfig.timeout} onTimeout={props.onGameEnd} />
        </div>
        <div className="score flexed">
          <img alt="scoreBg" className="scoreBg" src={getImages("score_panel")}></img>
          <div className="scoreSubText flexed">{`${getText("score")}`}</div>
          <div className="scoreText flexed">{props.resultData.score}</div>
        </div>
        <div className="pause">
          <ButtonComponent
            type="image"
            // disabled={displayQuestion || displayFeedback}
            normalImage={displayPauseMenu ? getImages("play_button") : getImages("pause_button")}
            hoverImage={
              displayPauseMenu ? getImages("play_button_hover") : getImages("pause_button_hover")
            }
            clickHandler={onPauseClick}
          />
        </div>
      </div>
      <div className="gameWrapper" style={{ backgroundImage: `url(${getImages("sub_bg")})` }}>
        <div className="mapCliper">
          <div className="map" ref={mapRef} style={{ bottom: "-12px" }}>
            {[...Array(vCount).keys()].map((_, vIndex) => {
              return [...Array(hCount).keys()].map((_, hIndex) => {
                let tileIndex = vIndex * hCount + hIndex + 1;
                return (
                  <HexgonTile
                    width={gameConfig.hexgonData.width}
                    height={gameConfig.hexgonData.height}
                    key={tileIndex}
                    tileIndex={tileIndex}
                    left={leftDistance * hIndex + 60}
                    bottom={
                      hIndex % 2
                        ? bottomDistance * vIndex +
                          gameConfig.hexgonData.height / 2 +
                          gameConfig.hexgonData.height / 84.66
                        : bottomDistance * vIndex
                    }
                    onTileClick={onTileClick}
                    isVisited={tilePath.includes(tileIndex)}
                    isBlocked={specialTilesSet.current.blockedTiles.includes(tileIndex)}
                    isBonus={specialTilesSet.current.bonusTiles.includes(tileIndex)}
                    currentTile={currentTile}
                    hCount={hCount}
                    activeTiles={activeTiles}
                    ref={(el) => (hexagonTileRef.current[tileIndex] = el)}
                    disableClick={!timerStatus}
                  />
                );
              });
            })}
            <img src={getImages("finish_line")} className="finishLineImg" ref={finishLineRef}></img>
          </div>
        </div>
        {displayQuestion && (
          <Question
            dataObject={questionBank.current[props.questionIndex]}
            tileClicked={displayQuestion.tileClicked}
            onSubmitClick={onSubmitClick}
            isBonusTile={displayQuestion.isBonusTile}
          />
        )}
        {displayFeedback && (
          <Feedback
            feedback={questionBank.current[props.questionIndex].feedback}
            answer={displayFeedback.status === "correct"}
            tileClicked={displayFeedback.tileClicked}
            onNextClick={onQuestionAttempt}
          />
        )}
        {displayPauseMenu && (
          <PauseMenu
            onRestartClick={() => setGameResetKey(!gameResetKey)}
            onHomeClick={props.onHomeClick}
          />
        )}
      </div>
      {displayDevicePlayButton && (
        <div className="playButtonHolder flexed">
          <ButtonComponent
            text={`${getText("resume")}a`}
            height="85px"
            width="311px"
            type="text"
            styleType="submit"
            clickHandler={onDevicePlayButtonClick}
          ></ButtonComponent>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    questionIndex: state.questionIndex,
    attemptCount: state.attemptCount,
    resultData: state.resultData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateQuestionIndex: (data) =>
      dispatch({
        type: "QUESTION_INDEX",
        payload: data,
      }),
    updateAttemptCount: (data) =>
      dispatch({
        type: "ATTEMPT_COUNT",
        payload: data,
      }),
    updateResultData: (data) =>
      dispatch({
        type: "RESULT_DATA",
        payload: data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Game);
