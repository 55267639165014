import { useState } from "react";
import "../css/PauseMenu.css";
import { getAudioStatus, getText, manageHowlVol } from "../helper/Helper";
import ButtonComponent from "./ButtonComponent";

const PauseMenu = (props) => {
  const [soundIcon, setSoundIcon] = useState(getAudioStatus() ? "sound_icon" : "mute_icon");

  function soundButtonHandler() {
    if (soundIcon === "sound_icon") {
      setSoundIcon("mute_icon");
      manageHowlVol(true);
    } else {
      setSoundIcon("sound_icon");
      manageHowlVol(false);
    }
  }

  return (
    <div className="PauseMenu">
      <ButtonComponent
        width={311}
        height={85}
        icon={"home_icon"}
        text={getText("home")}
        clickHandler={props.onHomeClick}
      />
      <ButtonComponent
        width={311}
        height={85}
        icon={"restart_icon"}
        text={getText("restart")}
        clickHandler={props.onRestartClick}
      />
      <ButtonComponent
        width={311}
        height={85}
        icon={soundIcon}
        text={getText("sound")}
        clickHandler={soundButtonHandler}
      />
    </div>
  );
};

export default PauseMenu;
