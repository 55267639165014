import "../css/MainContainer.css";
import ContentArea from "../components/ContentArea";
import { useEffect, useRef, useState } from "react";
import {
  getGameConfig,
  getImages,
  readJSONFile,
  setAudioConfig,
  setGameConfig,
  setImageConfig,
  setQuestionBank,
  setTextConfig,
} from "../helper/Helper";
import FontPreloader from "../components/FontPreloader";

const MainContainer = () => {
  const ref = useRef(null);
  const [isGameLoaded, setGameLoad] = useState(false);
  const [fontLoad, setFontLoad] = useState(false);

  useEffect(() => {
    if (fontLoad) onInit();
  }, [fontLoad]);

  const onInit = () => {
    // Loading all dependency JSON files for the project

    readJSONFile(`./assets/json/structure.json`, (data) => {
      setGameConfig(data);
      readJSONFile(`./assets/json/audio.json`, (data) => {
        setAudioConfig(data);
        readJSONFile(`./assets/json/images.json`, (data) => {
          setImageConfig(data, () => {
            readJSONFile(`./assets/json/${getGameConfig()["lang"]}_text.json`, (data) => {
              setTextConfig(data);
              readJSONFile(`./assets/json/questions.json`, (data) => {
                // document.addEventListener(
                //   "touchmove",
                //   (e) => {
                //     e.preventDefault();
                //   },
                //   { passive: false }
                // );

                setQuestionBank(data);
                setGameLoad(true);
              });
            });
          });
        });
      });
    });
  };

  return (
    <div className="MainContainer" ref={ref}>
      <FontPreloader onFontLoad={() => setFontLoad(!fontLoad)} />
      {!isGameLoaded ? <div className="loader"></div> : <ContentArea />}
    </div>
  );
};

export default MainContainer;
