import MainContainer from "./container/MainContainer";
import store from "./store";
import { Provider } from "react-redux";
import ReactGA from "react-ga4";

ReactGA.initialize("G-0713WRZS11");

function App() {
  return (
    <div className="App" style={{ width: "100%", height: "100%" }}>
      <Provider store={store}>
        <MainContainer />
      </Provider>
    </div>
  );
}

export default App;
