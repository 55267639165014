import "../css/RadioWithLabel.css";
import TextWithScroll from "./TextWithScroll";

const RadioWithLabel = (props) => {
  function clickHandler(e) {
    props.onRadioClick(props.index);
  }

  return (
    <div className="RadioWithLabel" onClick={clickHandler}>
      <div className="radioButtonContainer">
        <div className="outerCircle" id={`option-${props.index}`}>
          <div className={`innerCircle ${props.isSelected && "selected"}`}></div>
        </div>
      </div>
      <div className="optionTextContainer">
        <TextWithScroll text={props.optionText} height="100px" width="450px"></TextWithScroll>
      </div>
    </div>
  );
};

export default RadioWithLabel;
