import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import "../css/HexagonTile.css";
import { getImages, getText } from "../helper/Helper";

const HexagonTile = forwardRef((props, ref) => {
  const bonusRef = useRef(null);

  const [tileImage, setTileImage] = useState(null);
  const [isActive, setActive] = useState(null);

  const onTileClick = () => {
    props.onTileClick(props.tileIndex, props.isBonus);
  };

  useImperativeHandle(ref, () => ({
    playBonusAnim(cb) {
      bonusRef.current.classList.add("fadeIn");

      let fontSize = 10;
      let fontLimit = 58;

      const scale = () => {
        let currentFontSize = window
          .getComputedStyle(bonusRef.current, null)
          .getPropertyValue("font-size");
        if (parseInt(currentFontSize) < fontLimit) {
          if (parseInt(currentFontSize) === 38) {
            bonusRef.current.classList.remove("fadeIn");
          }
          fontSize += 1;
          bonusRef.current.style.fontSize = `${fontSize}px`;
        } else {
          window.cancelAnimationFrame(scale);
          cb && cb();
          return;
        }
        window.requestAnimationFrame(scale);
      };
      window.requestAnimationFrame(scale);
    },
  }));

  useEffect(() => {
    let state = 5;

    if (props.isBonus) state = 1;
    if (props.activeTiles.includes(props.tileIndex)) {
      setActive(true);
      // if ((props.currentTile % props.hCount) % 2 === 0) {
      //   if (props.currentTile === null || props.tileIndex === props.currentTile + props.hCount)
      //     state = 6;
      //   else if (props.tileIndex > props.currentTile + props.hCount) state = 7;
      //   else if (props.tileIndex < props.currentTile + props.hCount) state = 8;
      // } else {
      //   if (props.currentTile === null || props.tileIndex === props.currentTile + props.hCount)
      //     state = 6;
      //   else if (props.tileIndex > props.currentTile) state = 7;
      //   else if (props.tileIndex < props.currentTile) state = 8;
      // }
      state = 6;

      // if (props.isBonus) state += 3;
      if (props.isBonus) state = 9;
    } else setActive(false);
    if (props.isBlocked) state = 2;
    if (props.isVisited) state = 3;
    if (props.isBonus && props.isVisited) state = 12;
    if (props.currentTile === props.tileIndex) state = 4;

    setTileImage(getImages(`hexagon_${state}`));
  }, [props.activeTiles, props.currentTile]);

  return (
    <div
      className={`HexagonTile ${isActive ? "activeTile" : "disableTile"} ${
        props.disableClick && "disableTile"
      }`}
      style={{ width: props.width, height: props.height, left: props.left, bottom: props.bottom }}
      onClick={onTileClick}
    >
      <div
        style={{ backgroundImage: `url(${tileImage})` }}
        className={`stateImage ${(isActive || props.isBonus) && "spriteAnim"}`}
      ></div>
      <div className="bonusAnimHolder" ref={bonusRef}>
        {getText("bonusAnimText")}
      </div>
      {/* <img scr={tileImage} alt={"stateImage"}></img> */}
      {/* <div className="textIndex">{props.tileIndex}</div> */}
    </div>
  );
});

export default HexagonTile;
