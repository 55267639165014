import { getImages, getText } from "../helper/Helper";
import ButtonComponent from "./ButtonComponent";
import "../css/Intro.css";

const Intro = (props) => {
  function enterButtonHandler() {
    props.onEnterClick();
  }

  return (
    <div className="Intro" style={{ backgroundImage: `url(${getImages("intro_bg")})` }}>
      <div className="logoHolder">
        <img src={getImages("logo_02")}></img>
        <img src={getImages("logo_01")}></img>
      </div>

      <img src={getImages("main_heading")} className="mainHeading"></img>
      <ButtonComponent
        text={getText("enter")}
        height="85px"
        width="311px"
        type="text"
        styleType="submit"
        top="60%"
        left="auto"
        clickHandler={enterButtonHandler}
      ></ButtonComponent>
    </div>
  );
};

export default Intro;
