import { getImages, getText } from "../helper/Helper";
import "../css/Instruction.css";
import ButtonComponent from "./ButtonComponent";
import TextWithScroll from "./TextWithScroll";
import { useEffect, useRef } from "react";
const Instruction = (props) => {
  const instructionRef = useRef(null);

  useEffect(() => {
    const imageArr = instructionRef.current.querySelectorAll(".imageHolder");
    imageArr.forEach((ele) => {
      const image = document.createElement("img");
      image.src = getImages(ele.getAttribute("imageSrc"));
      image.classList.add("iconImage");
      ele.appendChild(image);
    });
  }, []);

  function backButtonHandler() {
    props.onBackClick();
  }
  return (
    <div
      className="Instruction"
      style={{ backgroundImage: `url(${getImages("info_bg")})` }}
      ref={instructionRef}
    >
      <img
        src={getImages("instructions_title")}
        className="instructionHeading"
      ></img>
      <div className="textContainer">
        <TextWithScroll
          text={getText("instructionText", true)}
          height="700px"
          width="630px"
          style={{ margin: "20px" }}
        ></TextWithScroll>
      </div>
      <ButtonComponent
        text={getText("back")}
        height="65px"
        width="236px"
        type="text"
        styleType="submit"
        top="89.5%"
        left="auto"
        clickHandler={backButtonHandler}
      ></ButtonComponent>
    </div>
  );
};

export default Instruction;
