import React from "react";
import "../css/LeaderBoardEntry.css";

const LeaderBoardEntry = (props) => {
  let bgColor = props.data.isCurrentPlayer ? "#7899bf" : "#80b0db";
  return (
    <div className="LeaderBoardEntry" style={{ backgroundColor: bgColor }}>
      <div className="rankEntry">{props.index}</div>
      <div className="playerNameEntry">{props.data.username}</div>
      <div className="scoreEntry">{props.data.score}</div>
    </div>
  );
};

export default LeaderBoardEntry;
