import React from "react";
import { getGameConfig, getImages, getText } from "../helper/Helper";
import ButtonComponent from "./ButtonComponent";
import "../css/Result.css";
import { connect } from "react-redux";
import { useState } from "react";
import SubmitScore from "./SubmitScore";

const Result = (props) => {
  const gameConfig = getGameConfig();

  const [showSubmitScore, setShowSubmitScore] = useState(false);

  let boxImage = getImages("resultBox");
  let starSrc = getImages("starImg");

  function submitScoreHandler() {
    setShowSubmitScore(true);
  }
  function playAgainHandler() {
    props.onPlayAgainClick();
  }
  function finishHandler() {
    props.onFinishPageClick();
  }
  function leaderboardHandler() {
    props.onLeaderboardClick();
  }

  function storeSubmitScore(name = "", email = "") {
    props.onSubmitScore(name, email);
    setShowSubmitScore(false);
  }

  function hideSubmitScorePopup() {
    setShowSubmitScore(false);
  }

  return (
    <div className="Result" style={{ backgroundImage: `url(${getImages("info_bg")})` }}>
      <img src={getImages("results_title")} className="heading"></img>
      <div className="scoreHolderContainer">
        <div className="scoreHolder firstBox" style={{ backgroundImage: `url(${boxImage})` }}>
          <img className="starImage" src={starSrc}></img>
          <div className="scoreLabel">{getText("highestScore")}</div>
          <div className="scoreNumber">{props.highestScore}</div>
        </div>
        <div className="scoreHolder secondBox" style={{ backgroundImage: `url(${boxImage})` }}>
          <img className="starImage" src={starSrc}></img>
          <div className="scoreLabel">{getText("yourScore")}</div>
          <div className="scoreNumber">{props.resultData.score}</div>
        </div>
      </div>
      <div className="statBox">
        <div className="contentContainer">
          <div className="statRow">
            <div className="text">{getText("statsText1")}</div>
            <div className="greyBox">
              {props.resultData.questionsAnswered * gameConfig.scoringData.correctAttempt}
            </div>
          </div>
          <hr></hr>
          <div className="statRow">
            <div className="text">{getText("statsText2")}</div>
            <div className="greyBox">
              {props.resultData.goalReached ? gameConfig.scoringData.goalReached : 0}
            </div>
          </div>
          <hr></hr>
          <div className="statRow">
            <div className="text">{getText("statsText3")}</div>
            <div className="greyBox">
              {props.resultData.bonusCollected * gameConfig.scoringData.bonusAttempt}
            </div>
          </div>
          <hr></hr>
          <div className="statRow">
            <div className="text">{getText("statsText4")}</div>
            <div className="greyBox">
              {props.resultData.timeLeft * gameConfig.scoringData.timeBonus}
            </div>
          </div>
        </div>
      </div>
      <div className="buttonHolder">
        <div className="buttonHolderTop">
          <ButtonComponent
            text={getText("submitScore")}
            height="85px"
            width="311px"
            type="text"
            styleType="submit resultButton"
            clickHandler={submitScoreHandler}
            disabled={props.isScoreSubmitted}
          />
          <ButtonComponent
            text={getText("playAgain")}
            height="85px"
            width="311px"
            type="text"
            styleType="submit resultButton"
            clickHandler={playAgainHandler}
          />
        </div>
        <div className="buttonHolderBottom">
          <ButtonComponent
            text={getText("leaderboard")}
            height="85px"
            width="311px"
            type="text"
            styleType="submit resultButton"
            clickHandler={leaderboardHandler}
          />
          <ButtonComponent
            text={getText("finish")}
            height="85px"
            width="311px"
            type="text"
            styleType="submit resultButton"
            clickHandler={finishHandler}
          />
        </div>
      </div>

      {showSubmitScore && (
        <SubmitScore
          hideSubmitScorePopup={hideSubmitScorePopup}
          storeSubmitScore={storeSubmitScore}
        ></SubmitScore>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    resultData: state.resultData,
  };
};

export default connect(mapStateToProps)(Result);
