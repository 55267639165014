import { getImages, getText } from "../helper/Helper";
import "../css/FurtherInfo.css";
import ButtonComponent from "./ButtonComponent";
import TextWithScroll from "./TextWithScroll";

const FurtherInfo = (props) => {
  function backButtonHandler() {
    props.onBackClick();
  }
  return (
    <div className="FurtherInfo" style={{ backgroundImage: `url(${getImages("info_bg")})` }}>
      <img src={getImages("further_info_title")} className="furtherInfoHeading"></img>
      <div className="infoContainer">
        <TextWithScroll
          text={getText("furtherInfoData", true)}
          height="700px"
          width="630px"
        ></TextWithScroll>
      </div>
      <ButtonComponent
        text={getText("back")}
        height="65px"
        width="236px"
        type="text"
        styleType="submit"
        top="89.5%"
        left="auto"
        clickHandler={backButtonHandler}
      ></ButtonComponent>
    </div>
  );
};

export default FurtherInfo;
