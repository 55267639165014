import { getImages, getText } from "../helper/Helper";
import "../css/Leaderboard.css";
import ButtonComponent from "./ButtonComponent";
import LeaderBoardEntry from "./leaderBoardEntry";

const Leaderboard = (props) => {
  function backButtonHandler() {
    props.onBackClick();
  }

  const leaderBoardData = props.leaderboardData || [];
  leaderBoardData.sort((a, b) => b.score - a.score);

  return (
    <div className="Leaderboard" style={{ backgroundImage: `url(${getImages("info_bg")})` }}>
      <img src={getImages("leaderBoard_title")} className="LeaderboardHeading"></img>
      <div className="leaderBoardBox">
        <div className="titleContainer">
          <div className="rank">{getText("rank")}</div>
          <div className="playerName">{getText("playerName")}</div>
          <div className="score">{getText("scoreLeaderboard")}</div>
        </div>
        <div className="entryContainer">
          {leaderBoardData.map((entry, index) => {
            return <LeaderBoardEntry data={entry} key={`entry_${index}`} index={index + 1} />;
          })}
        </div>
      </div>
      <ButtonComponent
        text={getText("back")}
        height="65px"
        width="236px"
        type="text"
        styleType="submit"
        top="88.5%"
        left="auto"
        clickHandler={backButtonHandler}
      ></ButtonComponent>
    </div>
  );
};

export default Leaderboard;
