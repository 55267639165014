import React, { useEffect, useRef, useState } from "react";
import "../css/TextWithScroll.css";
import $ from "jquery";
import "malihu-custom-scrollbar-plugin";
import "malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css";

require("jquery-mousewheel");

const TextWithScroll = (props) => {
  let { text, height = "auto", width = "auto" } = props;

  useEffect(() => {
    $("#textContainer").mCustomScrollbar({
      autoHideScrollbar: false,
    });
    for (let i = 0; i < 3; i++) {
      $("<div>", { class: "mCSB_dragger_innerLines" }).appendTo(".mCSB_dragger_bar");
    }
  }, []);

  return (
    // <Scrollbars
    //   style={{ width: width, height: height }}
    //   hideTracksWhenNotNeeded={true}
    //   renderView={(props) => <div {...props} className="view" />}
    //   renderTrackVertical={(props) => (
    //     <div {...props} className="track-vertical" />
    //   )}
    //   universal={true}
    //   renderThumbVertical={(props) => {
    //     return (
    //       <div {...props} className="thumb-vertical">
    //         <div className="thumbLine" style={{ top: "45%" }}></div>
    //         <div className="thumbLine" style={{ top: "48%" }}></div>
    //         <div className="thumbLine" style={{ top: "51%" }}></div>
    //       </div>
    //     );
    //   }}
    // >
    <div id="textContainer" style={{ width: width, height: height }}>
      {text}
    </div>
    //</Scrollbars>
  );
};

export default TextWithScroll;
