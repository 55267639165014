/** @format */

import React, { useEffect, useState } from "react";

const FontPreloader = (props) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
      props.onFontLoad();
    }, 3000);
  }, []);

  return visible ? (
    <div id="fontPreloader" style={{ position: "absolute", opacity: 0 }}>
      <div aria-hidden="true" style={{ fontFamily: "Roboto_Regular" }}>
        font1
      </div>
      <div aria-hidden="true" style={{ fontFamily: "Roboto_Medium" }}>
        font2
      </div>
      <div aria-hidden="true" style={{ fontFamily: "Roboto_Bold" }}>
        font3
      </div>
      <div aria-hidden="true" style={{ fontFamily: "Futura_Std_Bold" }}>
        font4
      </div>
      <div aria-hidden="true" style={{ fontFamily: "Futura_Std_Medium" }}>
        font5
      </div>
    </div>
  ) : (
    <div />
  );
};

export default FontPreloader;
