import ReactHtmlParser from "html-react-parser";
import { Howl } from "howler";

let playerImgObj = null;
let playerAudObj = null;
let howlAudObj = {};
let gameConfig = null;
let playerTextObj = null;
let questionBank = null;
let shiftedPhase = 1;
let audioStatus = true;
let isScreenPaused = false;
let playingRef = [];
let isMuted = false;
let html5Audio = new Audio();

/**
 * If the window is wider than it is tall, then scale the window to fit the height, otherwise scale the
 * window to fit the width.
 */
const onResizeFn = () => {
  const shellWidth = 720;
  const shellHeight = 1080;
  let newShellHeight;
  let newShellWidth;
  const actWid = Number(window.innerWidth);
  const actHgt = Number(window.innerHeight);

  let scale = 1;

  const mainContainer = document.querySelector(".ContentArea");

  newShellHeight = actHgt;
  scale = Number(shellHeight / newShellHeight).toFixed(2);
  newShellWidth = (shellWidth / shellHeight) * newShellHeight;
  const _aleft = actWid / 2 - Number(newShellWidth) / 2;
  if (_aleft < 0) {
    newShellWidth = actWid;
    scale = Number(shellWidth / newShellWidth).toFixed(2);
    newShellHeight = (shellHeight / shellWidth) * newShellWidth;
  }

  let scaleVal = 1 / scale;
  window.scaleVal = scaleVal;
  window.shellSize = {
    width: shellWidth,
    height: shellHeight,
  };

  mainContainer.style.transform =
    mainContainer.style.msTransform =
    mainContainer.style.WebkitTransform =
      `scale( ${scaleVal} )`;

  mainContainer.style.width = `${shellWidth}px`;
  mainContainer.style.height = `${shellHeight}px`;
};
window.onresize = onResizeFn;

const readJSONFile = function (file, callback) {
  var rawFile = new XMLHttpRequest();
  rawFile.overrideMimeType("application/json");
  rawFile.open("GET", file, true);
  rawFile.onreadystatechange = function () {
    if (rawFile.readyState === 4 && rawFile.status === 200) {
      if (callback) {
        callback(JSON.parse(rawFile.responseText));
      }
    }
  };
  rawFile.send(null);
};

// preload images and creates an image object
const setImageConfig = function (jsonObj, cb = null) {
  let count = 0;
  if (!playerImgObj) {
    playerImgObj = {};
  }
  for (let i in jsonObj) {
    const name = i.split(".")[0];
    let image = new Image();
    image.src = `assets/images/${jsonObj[i]}`;
    // eslint-disable-next-line
    image.onload = function () {
      count++;
      playerImgObj[name] = image;
      if (count === Object.keys(jsonObj).length) {
        if (cb) {
          cb();
        }
      }
    };
  }
};

const getImages = function (name) {
  return playerImgObj[name].src;
};

const setGameConfig = function (jsonObj) {
  gameConfig = jsonObj;
};

const getGameConfig = function () {
  return gameConfig;
};

const setAudioConfig = function (jsonObj) {
  playerAudObj = jsonObj;
  for (let i in playerAudObj) {
    howlAudObj[i] = new Howl({
      src: `assets/audios/${playerAudObj[i]}`,
      loop: false,
      volume: 1,
    });
    html5Audio.src = `assets/audios/${playerAudObj["bg_music"]}`;
    html5Audio.loop = true;
  }
};

const playHowlAudio = function (obj) {
  if (obj.id === "bg_music") {
    html5Audio.play();
  } else {
    if (obj.id) {
      howlAudObj[obj.id].play();
      playingRef.push(obj.id);
    }
  }
  if (obj.onEnded) {
    howlAudObj[obj.id].off("end").on("end", () => {
      const index = playingRef.indexOf(obj.id);
      if (index > -1) {
        // only splice array when item is found
        playingRef.splice(index, 1); // 2nd parameter means remove one item only
      }
      obj.onEnded();
    });
  }
};

const stopHowlAudio = function (obj, bool = false) {
  if (!bool) {
    if (obj.id) {
      howlAudObj[obj.id].stop();
    }
  } else {
    obj.stop();
  }
  html5Audio.pause();
  playingRef = [];
};

const pauseHowlAudio = function (obj) {
  if (obj.id === "bg_music") {
    html5Audio.pause();
  } else {
    if (playingRef.length > 0 && playingRef.indexOf(obj.id) !== -1) {
      howlAudObj[obj.id].pause();
    }
  }
};

const resumeHowlAudio = function (obj) {
  if (obj.id === "bg_music") {
    if (audioStatus) html5Audio.play();
  } else {
    if (playingRef.length > 0 && playingRef.indexOf(obj.id) !== -1) {
      howlAudObj[obj.id].play();
    }
  }
};

function manageHowlVol(isMute = false) {
  for (let id in howlAudObj) {
    if (isMute) {
      audioStatus = false;
      howlAudObj[id].volume(0);
      html5Audio.pause();
      // html5Audio.volume = 0;
    } else {
      audioStatus = true;
      howlAudObj[id].volume(1);
      html5Audio.play();
      // html5Audio.volume = 1;
    }
  }
}

const getAudioStatus = () => {
  return audioStatus;
};

const setTextConfig = function (data) {
  playerTextObj = data;
};

const getText = function (id, bool = false) {
  let val = playerTextObj[id];
  if (bool) {
    if (val) {
      return ReactHtmlParser(val);
    }
    return "";
  }
  return val;
};

const setQuestionBank = (jsonObj) => {
  questionBank = jsonObj;
};

const getQuestionBank = () => questionBank;

const getShiftedPhase = () => shiftedPhase;

const setShiftedPhase = (num) => (shiftedPhase = num);

/*
 * This function Shuffles the Array.
 * @param array is of type Array.
 */
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

const parseJsonData = (responseText) => {
  let obj = JSON.parse(responseText);
  obj.forEach((elm) => {
    if (elm.response && elm.response !== "") {
      elm.response = JSON.parse(elm.response);
    }
  });

  return obj;
};

const setScreenPause = (flag) => (isScreenPaused = flag);
const getScreenPause = () => isScreenPaused;

/* A JavaScript code that detects the device type and orientation. */
const BrowserDetect = {
  platformAndroid() {
    return !!navigator.userAgent.match(/Android/i);
  },
  platformBlackBerry() {
    return !!navigator.userAgent.match(/BlackBerry/i);
  },
  platformIOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i)
      ? true
      : navigator.maxTouchPoints > 0 && typeof window.orientation !== "undefined";
  },
  platformWindows() {
    return !!navigator.userAgent.match(/IEMobile/i);
  },
  isIPhone() {
    return navigator.userAgent.match(/iPhone|iPod/i)
      ? true
      : navigator.maxTouchPoints > 0 && typeof window.orientation !== "undefined";
  },
  isIpad() {
    return navigator.userAgent.match(/iPad/i)
      ? true
      : navigator.maxTouchPoints > 0 && typeof window.orientation !== "undefined";
  },
  isMobile() {
    let userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.search("android") > -1 && userAgent.search("mobile") > -1) return true;
    else if (userAgent.search("android") > -1 && !(userAgent.search("mobile") > -1)) return false;
    else
      return /iphone|ipod|blackberry|opera|mini|windows\sce|palm|smartphone|iemobile/i.test(
        userAgent
      );
  },
  isTablet() {
    let userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.search("android") > -1 && !(userAgent.search("mobile") > -1)) return true;
    else if (BrowserDetect.isIpad()) return true;
    else
      return /ipad|android|android 3.0|xoom|sch-i800|playbook|tablet|kindle/i.test(
        userAgent.toLowerCase()
      );
  },
  isDevice() {
    return (
      BrowserDetect.platformAndroid() ||
      BrowserDetect.platformBlackBerry() ||
      BrowserDetect.platformIOS() ||
      BrowserDetect.platformWindows()
    );
  },
  ie9() {
    return !!navigator.userAgent.match(/MSIE 9.0/i);
  },
  ie10() {
    return !!navigator.userAgent.match(/MSIE 10.0/i);
  },
  ie() {
    return (
      navigator.appName === "Microsoft Internet Explorer" ||
      !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/)) ||
      navigator.userAgent.indexOf("MSIE") > 0 ||
      !!navigator.userAgent.match(/Edge\/\d./i) ||
      !!navigator.userAgent.match(/MSIE 9.0/i) ||
      !!navigator.userAgent.match(/MSIE 10.0/i)
    );
  },
  getOrientation() {
    let returnObj = {
      type: "D", // values - M - Mobile / T - tablet / D - Desktop / Othe - not yet recognised as device or desktop
      orientation: undefined, // undfined for Destop / L - landscape / P - Portrait
    };
    if (BrowserDetect.isDevice()) {
      returnObj.orientation = window.orientation === 0 ? "P" : "L";
      returnObj.type = BrowserDetect.isMobile() ? "M" : BrowserDetect.isTablet() ? "T" : "Other";
    }
    return returnObj;
  },
};

const mouseEvents = {
  click: !BrowserDetect.isDevice() ? "click" : "touchend",
  down: !BrowserDetect.isDevice() ? "mousedown" : "touchstart",
  move: !BrowserDetect.isDevice() ? "mousemove" : "touchmove",
  up: !BrowserDetect.isDevice() ? "mouseup" : "touchend",
  over: !BrowserDetect.isDevice() ? "mouseover" : "touchstart",
  out: !BrowserDetect.isDevice() ? "mouseout" : "touchend",
};

export {
  onResizeFn,
  readJSONFile,
  setImageConfig,
  getImages,
  setGameConfig,
  getGameConfig,
  setAudioConfig,
  playHowlAudio,
  stopHowlAudio,
  setTextConfig,
  getText,
  setQuestionBank,
  getQuestionBank,
  shuffleArray,
  getShiftedPhase,
  setShiftedPhase,
  parseJsonData,
  pauseHowlAudio,
  resumeHowlAudio,
  manageHowlVol,
  getAudioStatus,
  setScreenPause,
  getScreenPause,
  mouseEvents,
  BrowserDetect,
};
