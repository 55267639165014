import React from "react";
import { getImages, getText } from "../helper/Helper";
import "../css/FinishPage.css";
import ButtonComponent from "./ButtonComponent";
import TextWithScroll from "./TextWithScroll";

const FinishPage = (props) => {
  function homeButtonHandler() {
    props.onHomeClick();
  }
  return (
    <div className="finishPage" style={{ backgroundImage: `url(${getImages("info_bg")})` }}>
      <div className="textContainer">
        <TextWithScroll
          text={getText("finishPageData", true)}
          height="780px"
          width="630px"
          style={{ margin: "20px" }}
        ></TextWithScroll>
      </div>
      <ButtonComponent
        text={getText("home")}
        height="65px"
        width="236px"
        type="text"
        styleType="submit"
        top="89%"
        left="auto"
        clickHandler={homeButtonHandler}
      />
    </div>
  );
};

export default FinishPage;
